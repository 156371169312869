<template>
  <div class="buttons dp-f">
    <div
      v-for="(el, ind) in state.showData"
      :key="ind"
      class="item dp-fc pt-r"
      :class="[state.index == ind ? 'itemac' : '', 'item' + ind,props.disabled?'':'cu-p','showType'+showType]"
      @click="butClick(ind)"
    >
      <img :src="el.img" class="mr-8 w-18 h-18" />
      {{ el.title }}
      <div class="angle"></div>
    </div>
  </div>
</template>
<script setup>
import { reactive, ref, unref, defineEmits, watch } from "vue";
const emit = defineEmits(["Emit"]);
const props = defineProps({
  showType: {
    type: Number,
    default: 0,//0套餐包 1金额 2支付
  },
  disabled: {
    type: Boolean,
    default: false
  },
});
const state = reactive({
  Data: [
    {
      title: "支付宝支付",
      img: require("@/assets/img/general/alipay.png"),
      value: 0,
    },
    {
      title: "微信支付",
      img: require("@/assets/img/general/wechat.png"),
      value: 1,
    },
    // {
    //   title: "对公账户打款",
    //   img: require("@/assets/img/general/bankcard.png"),
    //   value: 2,
    // },
  ], //菜单数据
  showData: [],
  index: 0, //选择下标
});
const setIndex=(ind)=>{
  state.index=ind
}
const butClick=(ind)=>{
  if(props.disabled)
    return
  state.index = ind 
  emit('Emit', ind)
}
const getshowData=()=>{
  if (props.showType == 2) {
    state.showData = [state.Data[0],state.Data[1]]
  }else if (props.showType == 1) {
    state.showData = state.Data;
    if (state.index == 3) {
      state.index = 0;
      emit("Emit", 0);
    }
  } else {
    state.showData = [
      ...state.Data,
      // {
      //   title: "余额支付",
      //   img: require("@/assets/img/general/balance.png"),
      //   value: 3,
      // },
    ];
  }
}
getshowData()
watch(props, () => {
  getshowData()
});
defineExpose({
  setIndex,
  rechargeMethod: state.Data[state.index].value,
});
</script>
<style lang="scss" scoped >
.showType2{
  display: none;
  margin-left: 0px!important;
}
.buttons {
  .item + .item {
    margin-left: 20px;
  }

  .item {
    width: 158px;
    height: 44px;
    border-radius: 6px;
    border: 1px solid #c9c9c9;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #06b4fd;
    overflow: hidden;
  }

  .itemac {
    display: flex;
    border: 1px solid #1890ff;

    .angle {
      position: absolute;
      right: -8px;
      bottom: -8px;
      width: 0;
      height: 0;
      border: 8px solid;
      border-color: transparent transparent transparent #1890ff;
      transform: rotate(45deg);
    }
  }

  .item1 {
    color: #08ba06;
  }

  .item1.itemac {
    border: 1px solid #08ba06;

    .angle {
      border-color: transparent transparent transparent #08ba06;
    }
  }

  .item2 {
    color: #f0a127;
  }

  .item2.itemac {
    border: 1px solid #f0a127;

    .angle {
      border-color: transparent transparent transparent #f0a127;
    }
  }
  .item3 {
    color: #7fc06e;
  }

  .item3.itemac {
    border: 1px solid #7fc06e;

    .angle {
      border-color: transparent transparent transparent #7fc06e;
    }
  }
}
</style>